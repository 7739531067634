.draft-container {
  background: #ffffff;
  color: #282828;
  position: relative;
  z-index: 30;
  text-align: left;
  pointer-events: auto;
  width: 400px;
  height: 400px;
  display: inline-block;
  vertical-align: bottom;
  border-radius: 10px 10px 0 0;
}

.draft-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: right;
  z-index: 1;
  pointer-events: none;
  overflow-y: auto;
  width: calc(100% - 66px);
  overflow: scroll;
  white-space: nowrap;
}

.minimized {
  height: 50px;
  width: 350px;
}

.center {
  z-index: 0;
  position: fixed;
  top: 8.5%;
  left: 8.5%;
  width: 83%;
  height: 83%;
  border-radius: 10px 10px 10px 10px;
  z-index: 999;
}

.header {
  border-radius: 10px 10px 0 0;
}

.content-container {
  height: calc(100% - 97px);
  width: 100%;
  overflow-y: auto;
}
