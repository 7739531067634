.popout {
  animation: popout 0.5s ease;
  -webkit-animation: popout 0.5s ease;
}

/* .ai-command-input[placeholder]:empty::before {
  content: attr(placeholder);
  color: #555;
}

.ai-command-input[placeholder]:empty:focus::before {
  content: '';
} */

.text-chip-red {
  color: #ffd4d4;
}

@keyframes popout {
  from {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes popout {
  from {
    -webkit-transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}
