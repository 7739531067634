.expression-builder {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  /* padding: 20px; */
}

.search-container {
  position: relative;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 5px;
}

.suggestion-item.selected {
  background-color: #abcbe8; /* Highlight color for selected items */
}

.suggestion-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.suggestion-description {
  font-size: 14px;
  color: #666;
}

.error-message {
  color: #ff4444;
  font-size: 14px;
  margin-top: 5px;
}

.function-help {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 4px;
}

.function-help h3 {
  margin: 0 0 10px 0;
}

.function-help h4 {
  margin: 15px 0 10px 0;
}

.parameters-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.parameter-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.parameter-name {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.optional-badge {
  font-size: 12px;
  background-color: #eee;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: normal;
  color: #666;
}

.parameter-description {
  font-size: 14px;
  color: #666;
}

.editor-container {
  position: relative;
  min-height: 20px; /* Ensure the editor has a minimum height */
}

.expression-editor {
  min-height: '100%';
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  overflow-y: auto;
  white-space: pre-wrap;
}

.expression-editor:focus {
  outline: none;
  border-color: #007bff;
}

.expression-editor.invalid {
  border-color: #ff4444;
}

.expression-editor.is-valid {
  border-color: #3eb731;
}

.inline-suggestions {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.suggestion-item {
  padding: 4px 8px;
  cursor: pointer;
}

.suggestion-item:hover,
.suggestion-item.selected {
  background-color: #c6ddf3;
}

.suggestion-item:hover {
  background-color: #f4f4f6;
}

.suggestion-item.help-text {
  font-style: italic;
  font-size: 11px;
  color: #888; /* Light gray for help text */
}

.suggestion-item.help-text:hover {
  background-color: white;
}

.dropdown-container {
  margin-bottom: 5px; /* Space between dropdown and editor */
}

.dropdown-container select {
  width: 200px; /* Adjust width as needed */
  padding: 5px; /* Add some padding */
}
