.onboarding-card {
  width: 50%;
  box-sizing: border-box;
  padding: 36px;
  margin-bottom: 25px;
  cursor: pointer;
  gap:10px;
  text-align: left;
  background-color: #ffffff;

  &:hover {
    border-color: var(--books-color);
    background: #f5f7fd;
  }
  .arrow{
    opacity: 0.4;
  }
}
